<template>
  <div id="app">
    <AppHeader />

    <div id="app-content">
      <span id="scroll-top-anchor" />
      <div
        :class="{ active: loader.isLoading }"
        class="ui inverted dimmer"
      >
        <div class="ui text loader">
          {{ loader.message }}
        </div>
      </div>

      <!-- //* Les views sont injectées ici -->
      <router-view class="page-content" />
    </div>

    <AppFooter />
  </div>
</template>

<script
  type="application/javascript"
  :src="
    baseUrl +
      'resources/leaflet-control-geocoder-1.13.0/Control.Geocoder.js'
  "
/>

<script>
import { mapState } from 'vuex';

import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      menuIsOpen: false,
      rightMenuIsOpen: true,
      baseUrl: this.$store.state.configuration.BASE_URL,
      width: window.innerWidth > 0 ? window.innerWidth : screen.width,
    };
  },

  computed: {
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS',
      'configuration',
      'loader',
    ]),
    ...mapState('projects', [
      'projects',
      'project',
    ])
  },
};
</script>

<style scoped>
.ui.active.dimmer {
  position: fixed;
}
</style>